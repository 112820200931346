@tailwind base;
@tailwind components;
@tailwind utilities;

/* Open Sans Regular */
@font-face {
  font-family: 'Open Sans';
  src: url('./fonts/OpenSans/OpenSans-Regular.ttf');
  src: url('./fonts/OpenSans/OpenSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* Open Sans Light */
@font-face {
  font-family: 'Open Sans';
  src: url('./fonts/OpenSans/OpenSans-Light.ttf');
  src: url('./fonts/OpenSans/OpenSans-Light.ttf') format('truetype');
  font-weight: light;
  font-style: normal;
}

/* Open Sans SemiBold */
@font-face {
  font-family: 'Open Sans';
  src: url('./fonts/OpenSans/OpenSans-SemiBold.ttf');
  src: url('./fonts/OpenSans/OpenSans-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

/* Open Sans Bold */
@font-face {
  font-family: 'Open Sans';
  src: url('./fonts/OpenSans/OpenSans-Bold.ttf');
  src: url('./fonts/OpenSans/OpenSans-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@layer base {
  :root {
    --pinv-ff-name: 'Open Sans';
    /* Base color palette */
    --pinv-clr-primary-400: #3f6db5;
    --pinv-clr-primary-500: #355b97;
    --pinv-clr-primary-600: #2a4879;
    --pinv-clr-secondary-400: #2c333a;
    --pinv-clr-secondary-500: #1a1f23;
    --pinv-clr-secondary-600: #121417;
    --pinv-clr-neutro-100: #f6f8f9;
    --pinv-clr-neutro-200: #f3f5f6;
    --pinv-clr-neutro-400: #d0d6dc;
    --pinv-clr-neutro-500: #607080;
    --pinv-clr-success-100: #c8f9ec;
    --pinv-clr-success-400: #14b88c;
    --pinv-clr-success-500: #0f8465;
    --pinv-clr-danger-100: #fdecef;
    --pinv-clr-danger-400: #e9203e;
    --pinv-clr-danger-500: #cb1430;
    --pinv-clr-danger-600: #a71127;
    --pinv-clr-warning-100: #fcfaee;
    --pinv-clr-warning-400: #dcbf30;
    --pinv-clr-info-100: #ebf2ff;
    --pinv-clr-info-400: #478eff;
    --pinv-clr-info-500: #005ef5;

    /* Generic */
    --pinv-clr-placeholder: var(--pinv-clr-neutro-500);
    --pinv-clr-disabled: var(--pinv-clr-neutro-400);
    --pinv-box-shadow-total: 0 0px 10px 5px rgba(0, 0, 0, 0.1);
    --pinv-clr-spid-button: #3867bf;
    --pinv-clr-spid-button-hover: #497bde;
    --pinv-clr-datepicker-hover: var(--pinv-clr-primary-600);

    /* Texts */
    color: var(--pinv-clr-secondary-400);
    --pinv-clr-page-title-primary: var(--pinv-clr-primary-400);
    --pinv-clr-page-title-secondary: var(--pinv-clr-secondary-400);

    /* Tables */
    --pinv-clr-table-header-bg: var(--pinv-clr-neutro-200);
    --pinv-clr-table-header-text: var(--pinv-clr-neutro-500);
    --pinv-clr-table-icon: var(--pinv-clr-neutro-500);
    --pinv-clr-table-bg-success: rgba(52, 211, 153, 0.1);
    --pinv-clr-table-bg-primary: rgba(0, 207, 210, 0.1);
    --pinv-clr-table-bg-danger: rgba(234, 24, 56, 0.1);

    /* Slide-overs */
    --pinv-clr-slideover-bg-light: rgba(
      157,
      163,
      174,
      0.1
    ); /* Sarebbe il #9da3ae con opacità 10%

    /* Company logo label */
    --pinv-clr-company-label-bg: var(--pinv-clr-neutro-200);

    /* Inputs */
    --pinv-clr-input-text: var(--pinv-clr-secondary-400);
    --pinv-clr-input-bg: white;
    --pinv-clr-input-bg-disabled: var(--pinv-clr-disabled);
    --pinv-clr-input-border: var(--pinv-clr-neutro-500);
    --pinv-clr-input-border-active: var(--pinv-clr-primary-400);
  }
  body {
    background-color: var(--pinv-clr-neutro-100);
    font-family: var(--pinv-ff-name);
  }
}

.btn {
  @apply flex items-center justify-center tracking-wider rounded-[10px];
}

.btn-primary {
  @apply text-white bg-primary-400 border-primary-400 border-2 border-solid hover:bg-primary-600 hover:border-primary-600 active:bg-primary-500 active:border-primary-500;
}

.btn-primary-disabled {
  @apply text-white border-solid border-2 bg-disabled border-disabled grayscale;
}

.btn-secondary-disabled {
  @apply text-disabled border-solid border-2 bg-white border-disabled grayscale;
}

.btn-secondary {
  @apply text-primary-400 bg-white border-primary-400 border-solid border-2 hover:text-primary-600 hover:border-primary-600 active:text-primary-500 active:border-primary-500;
}

.btn-tertiary {
  @apply text-white bg-secondary-400 border-secondary-400 border-solid border-2 hover:bg-secondary-600 hover:border-secondary-600 active:bg-secondary-500 active:border-secondary-500;
}

.btn-danger {
  @apply text-white bg-danger-400 border-danger-400 border-solid border-2 hover:bg-danger-600 hover:border-danger-600 active:bg-danger-500 active:border-danger-500;
}

.btn-small {
  @apply text-sm px-3 py-1;
}

.btn-medium {
  @apply px-6 py-2;
}

.btn-large {
  @apply text-xl px-6 py-2;
}

.btn-full {
  @apply text-xl px-6 py-2 w-full;
}

.shadow-top-only {
  box-shadow: 0 -5px 15px -1px rgba(0, 0, 0, 0.1), 0 -2px 4px -1px rgba(0, 0, 0, 0.06);
}
