.menu-opacity-transition {
  @apply opacity-0 group-hover:opacity-100 transition-opacity duration-0 group-hover:duration-100 group-hover:delay-100;
}

.pinv_pagination {
  list-style-type: none;
  display: flex;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 30px;
}

.pinv_pagination li {
  margin: 0 12px;
  font-size: 14px;
  color: var(--pinv-clr-secondary-400);
}

.pinv_pagination li.selected a {
  color: var(--pinv-clr-primary-400);
}

.pinv_pagination li.selected a:focus {
  outline: none;
  outline-offset: 0;
}

.pinv_tablecell_max_two_text_lines {
  overflow: hidden;
  max-width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

/* This style overwrite react-tabs default style and not render the border-bottom applied to ::after pseudo-element */
.react-tabs__tab:focus:after {
  background: transparent;
}
